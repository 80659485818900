import Box from '@spraoi/base/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import ContactCTA from '../../components/ContactCTA';
import ContactRequest from '../../components/ContactRequest';
import Icon from '../../components/Icon';
import IconFigure from '../../components/IconFigure';
import SEO from '../../components/SEO';
import Section from '../../components/Section';
import zem100l from '../../images/products/zem100l.png';
import zem600l from '../../images/products/zem600l.png';
import zem1001lp from '../../images/products/zem101lp.png';
import zem601l from '../../images/products/zem601l.png';
import zpc400 from '../../images/products/zpc400.png';
import zem111lp from '../../images/products/zem111lp.png';
import zvd511lp from '../../images/products/zvd511lp.png'

const ProductsPage = ({ location: { pathname } }) => (
  <>
    <SEO
      description="Industrial Ethernet Solutions - Single Pair Ethernet"
      pathname={pathname}
      title="Products | Industrial Ethernet Solutions"
    />
    <Section sx={{ pb: 0, textAlign: [null, null, 'center'] }}>
      <Box as="h1" sx={{ fontSize: [5, null, null, 6] }}>
      Industrial Ethernet Solutions
      </Box>
 
    </Section>
    
    <Section sx={{ px: [0, null, 5] }}>
      <Box
        as="ul"
        sx={{
          display: 'grid',
          gridColumnGap: 5,
          gridRowGap: ['1px', null, 5],
          gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
        }}
      >
        {[
          {
            description: (
              <>
                The Zemfyre ZEM600L is a 6-port DIN-rail Secure Unmanaged Switch with 4 ports of Single Pair Ethernet (SPE) 10BASE-T1L (IEC 63171-2 Connector) and 2 ports of 10/100BASE-TX. The switch provides secure 10Mb/s connectivity for up to 1,700m, optionally delivering power according to Power over Data Line (PoDL) standard.
                ZEM600L incorporates patent-pending technology providing security, diagnostics, and configuration options. 
              </>
            ),
            image: zem600l,
            title: 'ZEM600L',
            subtitle: 'Secure Unmanaged Switch',
            text: 'Single Pair Ethernet',
          },
          {
            description: (
              <>
                The Zemfyre ZEM100L is a one port Single Pair Ethernet (SPE) Media Converter (MC). It converts RJ45 based 10BASE-T interface to IEC 63171-2 based 10BASE-T1L and vice versa. ZEM100L enables SPE connectivity that can extend existing Ethernet for up to 1,700m.
              </>
            ),
            image: zem100l,
            title: 'ZEM100L',
            subtitle: 'Media Converter',
            text: 'Single Pair Ethernet'
          },
          {
            description: (
              <>
                The Zemfyre ZEM601L is a 6-port DIN-rail Secure Unmanaged Switch with 4 ports of Single Pair Ethernet (SPE) 10BASE-T1L (IEC 63171-1 Connector) and 2 ports of 10/100BASE-TX. The switch provides secure 10Mb/s connectivity for up to 1,700m, optionally delivering power according to Power over Data Line (PoDL) standard.
                ZEM601L incorporates patent-pending technology providing security, diagnostics, and configuration options.
              </>
            ),
            image: zem601l,
            title: 'ZEM601L',
            subtitle: 'Secure Unmanaged Switch',
            text: 'Single Pair Ethernet',
          },
          {
            description: (
              <>
                The Zemfyre ZEM101LP is a one port SPE Media Converter with PoDL and POE support. ZEM101LP converts RJ45 based 10BASE-T interface to SPE 10BASE-T1L (IEC 63171-1 connector) based and vice versa. 
                It also converts input power, either from SPE port PoDL or from an external power supply, to generate PoE power output to connect to existing PoE based sensors, actuators, and cameras. 
              </>
            ),
            image: zem1001lp,
            title: 'ZEM101LP',
            subtitle: 'Media Converter with PoDL/PoE',
            text: 'Single Pair Ethernet',
          },
          {
            description: (
              <>
                The Zemfyre ZEM111LP is the new patent pending small form factor SPE media converter that supports PoDL Class 10-14 input and (optionally) Class 10-15 output.
To facilitate a connection to traditional PoE cameras and sensors ZEM111LP converts input power, either over SPE port with PoDL or from an external power supply and generates PoE power output. Optionally ZEM111LP can support PoDL Class 10-15 output on SPE port derived from the PoE input port. 
              </>
            ),
            image: zem111lp,
            title: 'ZEM111LP',
            subtitle: 'SPE Media Converter with PoDL/PoE',
            text: 'Single Pair Ethernet',
          },
          {
            description: (
              <>
                The Zemfyre ZPC400 is a 4-Channel PoDL/SPoE Controller.  It works in conjunction with Zemfyre switching product series ZEM6XX and enables PoDL/SPoE control as per IEEE802.cg for power Classes 10-14 on all 4 ZEM6XX SPE Ports.  
              </>
            ),
            image: zpc400,
            title: 'ZPC400 ',
            subtitle: '4-Channel PoDL/SPoE Controller',
            text: 'Single Pair Ethernet',
          },
          {
            description: (
              <>
                The Zemfyre ZVD511LP is the industrial strength SPE PoDL-powered camera for video surveillance and monitoring applications.  It’s a full-color 5 Mpixel camera with one port of SPE 10BASE-T1L (IEC 63171-1 Connector). The camera supports 10Mb/s video stream for up to 1000 meters and is powered by PoDL Class 14 as per IEEE802.3cg.   
              </>
            ),
            image: zvd511lp,
            title: 'ZVD511LP ',
            subtitle: 'SPE PoDL Powered Camera',
            text: 'Single Pair Ethernet',
          },
        ].map((item, i) => (
          <Box
            key={i}
            as="li"
            sx={{
              bg: 'white',
              borderRadius: [null, null, 2],
              boxShadow: 3,
              display: 'flex',
              flexDirection: 'column',
              p: [6, null, null, 7],
            }}
          >
            <Box sx={{ height: '' }}>
              <img src={item.image} alt={item.image} style={{ maxWidth: "100%", maxHeight: "100%" }} />
            </Box>
            <Box as="h3" sx={{ mt: 5, textAlign: 'center' }}>
              {item.title}
            </Box>
            <Box as="h4" sx={{ mt: 3, textAlign: 'center' }}>
              {item.subtitle}
            </Box>
            <Box as="p" sx={{ mt: 2, textAlign: 'center' }}>
              {item.text}
            </Box>
            <Box
              as="p"
              sx={{ color: 'text.subtle', fontSize: 2, lineHeight: 2, mt: 4 }}
            >
              {item.description}
            </Box>
          </Box>
        ))}
      </Box>
    </Section>

    
              
    <ContactRequest />
  </>
);

ProductsPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductsPage;
